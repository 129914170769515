import { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios"
import {domain} from "../../util/domain"
import { message, Table } from "antd";

class StatsList extends Component {
    state = {
        data: [],
        total: 0,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        loading: false
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch(pagination.current)
    }

    fetch(current) {
        const pageSize = this.state.pagination.pageSize;
        this.setState({ loading: true })
        axios.get(domain + `/api/admin/stats/info?page=${current}&page_size=${pageSize}`, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
            }
            const { days, total } = data;
            this.setState({
                data: days,
                loading: false,
                pagination: {
                    current: current,
                    pageSize: pageSize,
                    total: total
                }
            })
        })
    }

    getColumns() {
        return [
            {
                title: "日期",
                dataIndex: "day",
                render:day => `${day}`
            },
            {
                title: "分享数量",
                dataIndex: "share_count",
                render:share_count => `${share_count}`
            },
            {
                title: "新用户数",
                dataIndex: "new_user_count",
                render:new_user_count => `${new_user_count}`
            },
            {
                title: "答题数",
                dataIndex: "answer_count",
                render:answer_count => `${answer_count}`
            },
            {
                title: "新增问题数",
                dataIndex: "question_count",
                render:question_count => `${question_count}`
            },
            {
                title: "答题用户",
                dataIndex: "active_user_count",
                render:active_user_count => `${active_user_count}`
            }
        ];
    }

    handleTableChange(pagination) {
        this.fetch(pagination.current, pagination.pageSize)
    }

    render() {
        const { data, pagination, loading } = this.state;
        const columns = this.getColumns()
        return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={e => this.handleTableChange(e)}
        />
        );
    }
}

StatsList = withRouter(StatsList)
export { StatsList }