import { Button, Input } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { Component } from "react";
import "./login.css"
import { withRouter } from 'react-router';
import { domain } from "../../util/domain";
import { Link } from "react-router-dom";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: ""
        };
    }

    setName(v) {
        this.setState({
            name: v
        })
    }
    setPassword(v) {
        this.setState({
            password: v
        })
    }

    doLogin() {
        axios.post(domain + "/api/admin/login", {
            name: this.state.name,
            password: this.state.password
        }).then(res => {
            if (res.status < 300 && res.data.code == 0) {
                localStorage.setItem("admin_token", res.data.data.authorization)
                // 跳转到主页
                this.props.history.push("/main")
            }
        }).catch(err => {
            console.error(err)
        })
    }


    render() {
        return (
            <Layout >
                <Content className="login-panel">
                    <h4>管理后台登录</h4>
                    <Input type value={this.state.name} onChange={(e) => {this.setName(e.target.value)}} style={{ margin: "10px 0" }}></Input>
                    <Input type="password" value={this.state.password} onChange={(e) => this.setPassword(e.target.value)} style={{ margin: "10px 0" }}></Input>
                    <Button type="primary" onClick={() => this.doLogin()}>登录</Button>

                    <div style={{ width: "1000px", margin: "10px auto" }}>
                    <span>ICP备案号：</span>
                    <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17058033号-1</a>
                </div>
                </Content>

                
                    

            </Layout>
        )
    }
}

LoginPage = withRouter(LoginPage)
export { LoginPage }