import { Component } from "react";
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";

import { QuestionList } from "../question/QuestionList";
import { AuditQuestionList } from "../question/AuditQuestionList";
import { RejectQuestionList } from "../question/RejectQuestionList";
import { OrderList } from "../order/OrderList";
import { UserList } from "../user/UserList";
import { StatsList } from "../stats/StatsList";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class MainPage extends Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
            <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="1" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/question"}} replace>问题列表</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/audit_question"}} replace>新增/审核问题</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/reject_question"}} replace>被拒问题</Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/user"}} replace>用户列表</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/order"}} replace>订单列表</Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<PieChartOutlined />}>
                        <Link to={{pathname:"/main/stats"}} replace>每日统计</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{backgroundColor: "white"}}>
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '0 16px'}}>
                    <div key={this.props.location.key}>
                    <Router key={this.state.current}>
                        <Switch>
                            <Route exact path="/main/question" component={ QuestionList }>
                            </Route>
                            <Route exact path="/main/audit_question" component={ AuditQuestionList }>
                            </Route>
                            <Route exact path="/main/reject_question" component={ RejectQuestionList }>
                            </Route>
                            <Route exact path="/main/user" component={ UserList }>
                            </Route>
                            <Route exact path="/main/order" component={ OrderList }>
                            </Route>
                            <Route exact path="/main/stats" component={ StatsList }>
                            </Route>
                        </Switch>
                    </Router>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17058033号-1</a>
                </Footer>
            </Layout>
        </Layout>
        );
    }
}

MainPage = withRouter(MainPage)
export { MainPage }