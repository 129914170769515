import { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios"
import {domain} from "../../util/domain"
import { message, Table } from "antd";

class QuestionList extends Component {
    state = {
        questionList: [],
        total: 0,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        loading: false
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch(pagination.current)
    }

    fetch(current) {
        const pageSize = this.state.pagination.pageSize;
        this.setState({ loading: true })
        axios.get(domain + `/api/admin/question/list?page=${current}&page_size=${pageSize}`, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
            }
            const { question_list, total } = data;
            this.setState({
                questionList: question_list,
                loading: false,
                pagination: {
                    current: current,
                    pageSize: pageSize,
                    total: total
                }
            })
        })
    }

    getColumns() {
        return [
            {
                title: "id",
                dataIndex: "id",
                render: id => `${id}`
            },
            {
                title: "如果",
                dataIndex: "if_content",
                render: c => `${c}`
            },
            {
                title: "但是",
                dataIndex: "but_content",
                render: c => `${c}`
            },
            {
                title: "作答数量",
                dataIndex: "answer_count",
                render: c => `${c}`
            },
            {
                title: "yes 数量",
                dataIndex: "yes_count",
                render: c => `${c}`
            },
            {
                title: "no 数量",
                dataIndex: "no_count",
                render: c => `${c}`
            },
            {
                title: "点赞数量",
                dataIndex: "top_count",
                render: c => `${c}`
            }
        ];
    }

    handleTableChange(pagination) {
        this.fetch(pagination.current, pagination.pageSize)
    }

    render() {
        const { questionList, pagination, loading } = this.state;
        const columns = this.getColumns()
        return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={questionList}
            pagination={pagination}
            loading={loading}
            onChange={(e) => this.handleTableChange(e)}
        />
        );
    }
}

QuestionList = withRouter(QuestionList)
export { QuestionList }