import { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios"
import {domain} from "../../util/domain"
import { message, Table } from "antd";

class OrderList extends Component {
    state = {
        data: [],
        total: 0,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        loading: false
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch(pagination.current)
    }

    fetch(current) {
        const pageSize = this.state.pagination.pageSize;
        this.setState({ loading: true })
        axios.get(domain + `/api/admin/order/list?page=${current}&page_size=${pageSize}`, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
            }
            const { order_list, total } = data;
            this.setState({
                data: order_list,
                loading: false,
                pagination: {
                    current: current,
                    pageSize: pageSize,
                    total: total
                }
            })
        })
    }

    getColumns() {
        return [
            {
                title: "订单号",
                dataIndex: "order_sn",
                render: order_sn => `${order_sn}`
            },
            {
                title: "第三方订单号",
                dataIndex: "third_order_sn",
                render: third_order_sn => `${third_order_sn}`
            },
            {
                title: "用户ID",
                dataIndex: "user_id",
                render: user_id => `${user_id}`
            },
            {
                title: "订单金额",
                dataIndex: "order_money",
                render: order_money => `${order_money}`
            },
            {
                title: "实付金额",
                dataIndex: "real_money",
                render: real_money => `${real_money}`
            },
            {
                title: "status",
                dataIndex: "status",
                render: status => `${status}`
            },
            {
                title: "支付时间",
                dataIndex: "pay_time",
                render: pay_time => `${pay_time}`
            }
        ];
    }

    handleTableChange(pagination) {
        this.fetch(pagination.current, pagination.pageSize)
    }

    render() {
        const { data, pagination, loading } = this.state;
        const columns = this.getColumns()
        return (
        <Table
            columns={columns}
            rowKey={record => record.order_sn}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={e => this.handleTableChange(e)}
        />
        );
    }
}

OrderList = withRouter(OrderList)
export { OrderList }