import { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios"
import {domain} from "../../util/domain"
import { message, Table, Modal, Button, Form, Select, Input } from "antd";

const { Option } = Select;

class AuditQuestionList extends Component {
    state = {
        questionList: [],
        total: 0,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        loading: false,
        modeList: [],  // key, label

        editVisible: false,
        editInfo: {
            id: 0,
            if_content: "",
            but_content: "",
            mode: "",
            yes_count: 0,
            no_count: 0,
        }
    }

    initEditInfo() {
        this.setState({
            editInfo: {
                id: 0,
                if_content: "",
                but_content: "",
                mode: "",
                yes_count: 0,
                no_count: 0,
            }
        })
    }

    setEditVisible(visible) {
        this.setState({
            editVisible: visible
        })
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch(pagination.current)
        this.fetchMode();
    }

    fetchMode() {
        axios.get(domain + "/api/admin/mode/list", {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
                return
            }
            this.setState({
                modeList: data.mode_list
            })
        })
    }

    fetch(current) {
        const pageSize = this.state.pagination.pageSize;
        this.setState({ loading: true })
        axios.get(domain + `/api/admin/question/auditing_list?page=${current}&page_size=${pageSize}`, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
                return
            }
            const { question_list, total } = data;
            this.setState({
                questionList: question_list,
                loading: false,
                pagination: {
                    current: current,
                    pageSize: pageSize,
                    total: total
                }
            })
        })
    }

    getColumns() {
        return [
            {
                title: "id",
                dataIndex: "id",
                render: id => `${id}`
            },
            {
                title: "如果",
                dataIndex: "if_content",
                render: c => `${c}`
            },
            {
                title: "但是",
                dataIndex: "but_content",
                render: c => `${c}`
            },
            {
                title: "作答数量",
                dataIndex: "answer_count",
                render: c => `${c}`
            },
            {
                title: "yes 数量",
                dataIndex: "yes_count",
                render: c => `${c}`
            },
            {
                title: "no 数量",
                dataIndex: "no_count",
                render: c => `${c}`
            },
            {
                title: "点赞数量",
                dataIndex: "top_count",
                render: c => `${c}`
            },
            {
                title: "操作",
                dataIndex: "id",
                render: (c, e) => {
                    return (
                        <div>
                            <Button type="primary" onClick={() => this.toEditQuestion(e)} style={{ margin: "0 2px" }}>编辑</Button>
                            <Button type="primary" onClick={() => this.auditQuestion(e.id, 1)} style={{ margin: "0 2px" }}>通过</Button>
                            <Button type="danger" onClick={() => this.auditQuestion(e.id, -1)} style={{ margin: "0 2px" }}>拒绝</Button>
                        </div>
                    )
                }
            }
        ];
    }

    toEditQuestion(e) {
        this.setState({
            editInfo: e,
            editVisible: true
        });
    }

    handleTableChange(pagination) {
        this.fetch(pagination.current, pagination.pageSize)
    }

    auditQuestion(id, result) {
        if (id <= 0 || (result != -1 && result != 1)) {
            message.error("参数错误");
            return
        }
        axios.post(domain + "/api/admin/question/audit", {
            "id": id,
            "result": result
        }, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
                return
            }
            message.success("保存成功")
            const { pagination } = this.state;
            this.fetch(pagination.current)
        })
    }

    saveQuestion() {
        console.log(this.state.editInfo)
        // if_content but_content 不能为空，mode 不能为空
        const editInfo = this.state.editInfo;
        if (editInfo.if_content == "" || editInfo.but_content == "" || editInfo.mode == "") {
            message.error("所有内容必填");
            return
        }
        axios.post(domain + "/api/admin/question/edit", editInfo, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
                return
            }
            this.initEditInfo()
            message.success("保存成功")
            const { pagination } = this.state;
            this.fetch(pagination.current)
            setTimeout(() => {
                this.setEditVisible(false)
            }, 200)
        })
    }

    showAddQuestion() {this.setState({
        editInfo: {
            id: 0,
            if_content: "",
            but_content: "",
            mode: "",
            yes_count: 0,
            no_count: 0,
        },
        editVisible: true
    })
    }

    onEditInfoChange(key, event) {
        const { editInfo } = this.state;
        var value = event.target.value.trim();
        if (key == "yes_count" || key == "no_count") {
            value= parseInt(value)
        }
        editInfo[key] = value;
        this.setState({ editInfo })
    }

    onModeChange(e) {
        const { editInfo } = this.state;
        editInfo.mode = e;
        this.setState({ editInfo })
    }

    getOptions() {
        return this.state.modeList.map(m => {
            return (
                <Option value={m.key} key={m.key}>
                    {m.label}
                </Option>
            )
        })
    }

    render() {
        const { questionList, pagination, loading } = this.state;
        const columns = this.getColumns();
        return (
            <div>
                <Button style={{margin: "10px 0"}} type="primary" onClick={() => {this.showAddQuestion()}}>新增问题</Button>
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={questionList}
                    pagination={pagination}
                    loading={loading}
                    onChange={e => this.handleTableChange(e)}
                />

                <Modal
                    title="编辑问题"
                    onOk={() => {this.saveQuestion()}}
                    onCancel={() => { this.setEditVisible(false); }}
                    visible={this.state.editVisible}>
                        <div>
                            <Form.Item  label="如果">
                                <Input onChange={e => {this.onEditInfoChange("if_content", e)}} value={this.state.editInfo.if_content}></Input>
                            </Form.Item>
                            <Form.Item label="但是">
                                <Input onChange={e => {this.onEditInfoChange("but_content", e)}} value={this.state.editInfo.but_content}></Input>
                            </Form.Item>
                            <Form.Item label="yes 数量">
                                <Input type="number"  disabled={this.state.editInfo.id > 0} onChange={e => {this.onEditInfoChange("yes_count", e)}} value={this.state.editInfo.yes_count}></Input>
                            </Form.Item>
                            <Form.Item label="no 数量" >
                                <Input type="number" disabled={this.state.editInfo.id > 0} onChange={e => {this.onEditInfoChange("no_count", e)}} value={this.state.editInfo.no_count}></Input>
                            </Form.Item>
                            <Form.Item label="mode">
                                <Select value={this.state.editInfo.mode} onChange={(e) => {this.onModeChange(e)}}>
                                    {
                                        this.getOptions()
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                </Modal>
            </div>
        );
    }
}

AuditQuestionList = withRouter(AuditQuestionList)
export { AuditQuestionList }