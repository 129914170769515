import { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios"
import {domain} from "../../util/domain"
import { message, Table } from "antd";

class UserList extends Component {
    state = {
        data: [],
        total: 0,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        loading: false
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch(pagination.current)
    }

    fetch(current) {
        const pageSize = this.state.pagination.pageSize;
        this.setState({ loading: true })
        axios.get(domain + `/api/admin/user/list?page=${current}&page_size=${pageSize}`, {
            headers: {
                Authorization: localStorage.getItem("admin_token")
            }
        }).then(res => {
            const { code, data, msg } = res.data;
            if (code != 0) {
                message.error(msg)
            }
            const { user_list, total } = data;
            this.setState({
                data: user_list,
                loading: false,
                pagination: {
                    current: current,
                    pageSize: pageSize,
                    total: total
                }
            })
        })
    }

    getColumns() {
        return [
            {
                title: "id",
                dataIndex: "id",
                render: id => `${id}`
            },
            {
                title: "device type",
                dataIndex: "device",
                render: device => `${device.device_type}`
            },
            {
                title: "device code",
                dataIndex: "device",
                render: device => `${device.device_code}`
            }
        ];
    }

    handleTableChange(pagination) {
        this.fetch(pagination.current, pagination.pageSize)
    }

    render() {
        const { data, pagination, loading } = this.state;
        const columns = this.getColumns()
        return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={e => this.handleTableChange(e)}
        />
        );
    }
}

UserList = withRouter(UserList)
export { UserList }